import React from "react";

import Popup from "./elements/popup";
import Confirm from "./elements/confirm";
import Clock from "./elements/clock";
import { InputField, Button, NewPassword, HoverDiv, SelectField, Disclaimer, handleChange } from "./elements/fields";
import { request, goUrl } from "./elements/functions";
import * as IS from "./elements/validator";

import loading_img from "./loading.png";

import "./styles/main.css";

const Gdpr = (props) => {
    const { name, value, onChange } = props;

    function handleClick(event) {
        if (IS.func(onChange)) onChange({ target: { name: name, value: !value }});
    }

    return (
        <line style={{ padding: "unset", justifyContent: "flex-start" }}>
            <div className={value ? "selectBox selected" : "selectBox"} onClick={handleClick} style={{ marginRight: "0.5em" }} />
            <span>Dichiaro di aver letto <a href="/metas/docs/gdpr.pdf">l'informativa</a> e autorizzo il trattamento dei miei dati personali per i fini in essa indicati</span>
        </line>
    )
}

const defaultDiscalimer = [
    `Accesso esclusivo agli autorizzati da @azienda@.`,
    `Ogni accesso ed attività nel sistema viene registrata.`
];
const defaultLinks = [
    { label: "Problemi di Accesso ?", query: "action=accesso" },
    { label: "Richiedi Attivazione Utenza", query: "action=form" }
];

function focusField(name) {
    try {
        const input = document.querySelector(`input[name='${name}']`);
        if (input) {
            input.style.borderColor = "red";
            input.classList.add("bounceButton");
            input.addEventListener("animationend", () => { input.classList.remove("bounceButton") });
        }
    } catch(e) {
        console.log(e);
    }
}

const initState = {
    fields: [],
    obj: {},
    email: "",
    username: "",
    password: "",
    azienda: "",
    gdpr: false,
    token: null,
    action: null,
    result: null,
    timeout: null,
    topic: null,
    resCode: 0
}

class Login extends React.Component {
    state = { 
        ...initState,
        loading: false
    } 

    componentDidMount() {
        this.loadItems();
    }

    componentWillUnmount() {
        const { timeout } = this.state;
        if (timeout) clearTimeout(timeout);
    }

    loadItems = (ob) => {
        try {
            if (this.state.timeout) clearTimeout(this.state.timeout); 
            this.setState({ ...initState, loading: true });
            let el = {};
            const queryParams = new URLSearchParams(window.location.search);
            if (queryParams.size) {
                for (const [k, v] of queryParams.entries()) {
                    el[k] = v;
                }
                if (location.href.includes("?")) history.pushState({}, null, location.href.split("?")[0]);
            }
            const azienda = getComputedStyle(document.documentElement).getPropertyValue("--nomeAzienda");
            if (IS.string(azienda)) el.azienda = azienda;
            else el.azienda = document.title;
            if (IS.object(ob)) el = { ...el, ...ob };
            if (IS.string(el.action) && el.action === "form") this.loadFields(el);
            else this.setState({ ...el, loading: false });
        } catch(e) {
            Popup(e);
            this.setState({ loading: false });
        }
    }

    loadFields = async (el) => {
        try {
            this.setState({ loading: true, fields: initState.fields, obj: initState.obj });  
            const r = await request("login/getFields", "noAuth");
            if (r.statusCode === 200) {
                let o = {};
                for (const f of r.body) {
                    if (IS.string(f)) o[f] = "";
                    else if (IS.object(f) && IS.string(f.name)) o[f.name] = "";
                }
                this.setState({ fields: r.body, obj: o });
            } else Popup(r);  
            if (IS.object(el)) this.setState({ ...el, loading: false });
            else this.setState({ loading: false });                
        } catch(e) {
            Popup(e);
            this.setState({ loading: false });
        }
    }

    handleInputChange = (event) => { 
        this.setState(handleChange(event, this.state));              
    } 

    checkFields = (callback) => {
        const { fields, obj } = this.state;
        if (IS.array(fields, true)) {
            let ok = true;
            for (const f of fields) {
                try {
                    let name;
                    let label;
                    let type;
                    if (IS.string(f)) name = f;
                    else if (IS.object(f)) {
                        if (IS.string(f.name)) name = f.name;
                        if (IS.string(f.label)) label = f.label;
                        if (IS.string(f.type) && IS.func(IS[f.type])) type = f.type;
                    }
                    if (IS.string(name)) {
                        if (IS.string(type)) {
                            if (!IS[type](obj[name])) {
                                this.showResult(`COMPILARE ${IS.string(label) ? label.toUpperCase() : name.toUpperCase()} TIPO ${type.toUpperCase()}`, 10);
                                focusField(name);
                                ok = false;
                                break;
                            }
                        } else if (!IS.string(obj[name])) {
                            this.showResult(`COMPILARE ${IS.string(label) ? label.toUpperCase() : name.toUpperCase()}`, 10);
                            focusField(name);
                            ok = false;
                            break;
                        }
                    } 
                } catch(e) {
                    console.log(e);
                }               
            }
            if (IS.func(callback)) callback(ok);
        }
    }

    showResult = (el, delay) => {
        try {
            const { timeout } = this.state;
            if (timeout) clearTimeout(timeout);            
            let o = { timeout: null, result: null, resCode: initState.resCode };   
            if (IS.string(el)) o.result = el;
            else if (IS.error(el)) o = { ...o, result: `ERROR: ${e.message}`, resCode: 500 };
            else if (IS.object(el)) {
                if (IS.string(el.result)) o.result = el.result;
                else if (IS.string(el.message)) o.result = el.message;
                else if (IS.string(el.text)) o.result = el.text;
                if (IS.statusCode(el.statusCode)) o.resCode = el.statusCode;
            }         
            this.setState({ ...o });
            if (IS.number(delay)) {
                const newTimeout = setTimeout(() => {
                    this.setState({ result: null, timeout: null, resCode: initState.resCode });
                }, delay * 1000);
                this.setState({ timeout: newTimeout });
            }  
        } catch(e) {
            Popup(e);
        }    
    }
    
    postLogin = async () => {
        const { username, password } = this.state;
        try {
            if (!IS.string(username)) {
                this.showResult(`INSERIRE USERNAME`);
                focusField("username");
            } else if (!IS.string(password)) {
                this.showResult(`INSERIRE PASSWORD`);
                focusField("password");
            } else {
                this.setState({ loading: true });
                const r = await request("login/getToken", { username: username, password: password }, "noAuth");             
                if (r.statusCode === 200 && r.token) {
                    if (r.role === "reset") {
                        HoverDiv(`NECESSARIO CAMBIO PASSWORD`, 10000);
                        this.setState({ loading: false, token: r.token, username: "", password: "", action: r.role });
                    } else {
                        localStorage.setItem("token", r.token);
                        localStorage.setItem("user", r.user);
                        localStorage.setItem("role", r.role); 
                        goUrl("/");  
                    }              
                } else {
                    this.showResult(r);
                    this.setState({ loading: false });
                }
            }          
        } catch(e) {
            this.showResult(e);
            this.setState({ loading: false });
        }        
    }

    postReset = async () => {
        try {
            const { password, token } = this.state;
            if (!IS.string(password)) {
                this.showResult(`INSERIRE PASSWORD`);
                focusField("password");
            } else {
                this.setState({ loading: true });
                const r = await request("reset/emailPass", { password: password }, token);             
                if (r.statusCode === 200 && IS.string(r.user)) {
                    HoverDiv(r, 10000);
                    this.setState({ ...initState, loading: false, username: r.user });                    
                } else {
                    HoverDiv(r, 10000);
                    if (r.statusCode === 403) this.loadItems();
                    else this.setState({ loading: false });
                }
            }           
        } catch(e) {
            Popup(e);
            this.setState({ loading: false });
        }        
    }

    postRegistra = () => {
        const { obj } = this.state;
        this.checkFields((ok) => {
            if (ok) Confirm(`Richiedere attivazione utenza ?`, async (confirm) => {
                if (confirm) {
                    try {
                        this.setState({ loading: true });
                        const r = await request("login/userCreation", "noAuth", { ...obj })
                        this.showResult(r);
                        this.setState({ loading: false });
                    } catch(e) {
                        this.showResult(e);
                        this.setState({ loading: false });
                    }
                }
            })
            else HoverDiv(`ERRORE COMPILAZIONE CAMPI`, 5000);
        })  
    }

    postAccesso = async () => {
        const { email, username } = this.state;
        try {
            if (!IS.string(username)) {
                this.showResult(`INSERIRE USERNAME`);
                focusField("username");
            } else if (!IS.email(email)) {
                this.showResult(`INSERIRE EMAIL VALIDA`);
                focusField("email");
            } else {
                this.setState({ loading: true });
                const r = await request("login/recoverPassword", { user: username, email: email }, "noAuth");                
                this.showResult(r);
                this.setState({ loading: false });   
            }           
        } catch(e) {
            Popup(e);
            this.setState({ loading: false });
        }        
    }

    handleSubmit = () => {
        const { token, action, timeout, loading } = this.state;
        if (!loading) {            
            if (timeout) clearTimeout(timeout);
            this.setState({ result: null, timeout: null, resCode: initState.resCode });
            if (IS.string(action)) {                
                if (action === "accesso") this.postAccesso();
                else if (action === "form") this.postRegistra();
                else if (IS.string(token)) this.postReset();
            } else this.postLogin();
        }
    }

    handleKeyDown = (event) => {
        if (event.key === "Enter") this.handleSubmit();
    }

    render() { 
        const { email, username, password, action, azienda, result, resCode, fields, obj, gdpr, loading } = this.state;

        if (loading) return (
            <div className="columnContainer">
                <Clock />
                <img src="/metas/logo.png" alt="logo" class="logo" />
                <img src={loading_img} alt="loading" className="spin" style={{ width: "10em" }} />  
            </div>
        )
        else if (IS.string(action) && action === "form") return (
            <div className="columnContainer" onKeyDown={this.handleKeyDown}>
                <Clock />
                <img src="/metas/logo.png" alt="logo" class="logo" />
                {IS.array(fields, true) ? (
                    <React.Fragment>
                        <line style={{ padding: "unset", flexWrap: "wrap", maxWidth: "32em" }}>
                            {fields.map((el, ind) => {
                                let common = {
                                    key: `fields.${ind}`,
                                    onChange: this.handleInputChange
                                }
                                if (IS.string(el)) {
                                    common.name = `obj.${el}`;
                                    common.label = el;
                                } else if (IS.object(el) && IS.string(el.name)) {
                                    common = { ...common, ...el };
                                    common.name = `obj.${el.name}`;
                                    common.label = IS.string(el.label) ? el.label : el.name;
                                }                            
                                if (IS.string(common.name)) common.value = obj[common.name];
                                else {
                                    common.value = `${el}`;
                                    common.disabled = true;
                                }                            
                                if (IS.array(common.options)) return (<SelectField { ...common } />)
                                else return (<InputField size={30} { ...common } />)
                            })}
                        </line>
                        <Gdpr name="gdpr" value={gdpr} onChange={this.handleInputChange} />
                        <Button label="INVIA" onClick={this.handleSubmit} disabled={!gdpr} /> 
                    </React.Fragment>
                ) : (
                    <Button label="RICARICA" onClick={() => this.loadFields({ action: action })} />
                )}                
                <Disclaimer 
                    azienda={azienda} 
                    text={[
                        `Richiedi la creazione di un'utenza compilando tutti i campi sopra.`,
                        `La creazione dell'utenza è a discrezione di @azienda@.`,
                        `Una volta inviata la richiesta verrete notificati all'email indicata quando l'utenza sarà attivata.`
                    ]}
                    resCode={resCode}
                    result={result}
                    links={[
                        defaultLinks[0],
                        { label: "Contatta Supporto Tecnico", href: "mailto:areariservata@cemambiente.it" },
                        { label: "Login", location: "login" }
                    ]}
                />               
            </div>
        )
        else if (IS.string(action) && action === "accesso") return (
            <div className="columnContainer" onKeyDown={this.handleKeyDown} >
                <Clock />               
                <img src="/metas/logo.png" alt="logo" class="logo" />
                <h3>RECUPERO PASSWORD</h3>
                <InputField 
                    name="username" 
                    value={username} 
                    onChange={this.handleInputChange}
                    noSpan
                    placeholder="... Username ..."
                    size={30} 
                /> 
                <InputField 
                    name="email" 
                    type="email" 
                    value={email} 
                    onChange={this.handleInputChange} 
                    noSpan
                    placeholder="... Email ..."
                    size={30}
                />
                <Button 
                    label="INVIA" 
                    onClick={this.handleSubmit} 
                    disabled={!IS.string(username) || !IS.email(email)} 
                />  
                <Disclaimer 
                    azienda={azienda} 
                    text={[
                        `Inserisci la tua USERNAME e la tua EMAIL per richiedere il reset della password.`,
                        `Se non ti ricordi i tuoi dati, Contattaci usando il link sotto.`
                    ]}
                    resCode={resCode}
                    result={result}
                    links={[
                        { label: "Contatta Supporto Tecnico", href: "mailto:greenalliance@cemambiente.it" },
                        { label: "Login", location: "login" }
                    ]}
                />  
            </div>
        )
        else if (IS.string(action)) return (
            <div className="columnContainer" onKeyDown={this.handleKeyDown} >
                <Clock />
                <img src="/metas/logo.png" alt="logo" class="logo" />
                {action === "activate" ? (
                    <h3>ATTIVAZIONE UTENTE</h3>
                ) : (
                    <h3>CAMBIO PASSWORD</h3>
                )}
                <NewPassword 
                    name="password"
                    value={password}
                    onChange={this.handleInputChange}
                    size={30}
                    placeholder="... Nuova Password ..."
                    noSpan
                />
                {action === "activate" && (
                    <Gdpr name="gdpr" value={gdpr} onChange={this.handleInputChange} />
                )}
                <Button 
                    label={action === "activate" ? "ATTIVA" : "CAMBIA"} 
                    onClick={this.handleSubmit} 
                    disabled={(!IS.string(password) || (action === "activate" && !gdpr))}
                />                                       
                <Disclaimer 
                    azienda={azienda} 
                    text={defaultDiscalimer}
                    resCode={resCode}
                    result={result}
                    links={[
                        ...defaultLinks,
                        { label: "Login", location: "login" }
                    ]}
                />           
            </div>
        )
        else return (
            <div className="columnContainer" onKeyDown={this.handleKeyDown} >
                <Clock />
                <img src="/metas/logo.png" alt="logo" class="logo" />
                <InputField 
                    name="username" 
                    value={username} 
                    onChange={this.handleInputChange}
                    noSpan
                    placeholder="... Username or Email ..."
                    size={40} 
                /> 
                <InputField 
                    name="password" 
                    type="password" 
                    value={password} 
                    onChange={this.handleInputChange} 
                    noSpan
                    placeholder="... Password ..."
                    size={40}
                />
                <Button 
                    label="LOGIN" 
                    onClick={this.handleSubmit} 
                    disabled={!(IS.string(username) && IS.string(password))}
                />                          
                <Disclaimer 
                    azienda={azienda} 
                    text={defaultDiscalimer}
                    resCode={resCode}
                    result={result}
                    links={defaultLinks}
                />           
            </div>
        )
    }
}
 
export default Login;